import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPost, getComments, deletePost, getUserByUsername } from '../../utils/api';
import './postStyle.css';
import CommentBox from './comment/CreateCommentBox.jsx';
import CommentsComponent from './comment/CommentComponent.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const PostPage = () => {
  const Navigate = useNavigate();
  let { id } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchPostAndComments = async () => {
      const fetchedPost = await getPost(id);
      setPost(fetchedPost.post);
      fetchComments(currentPage);  // Fetch initial comments when component mounts or post id changes
      const fetchedUser = await getUserByUsername(fetchedPost.post.created_by);
      setUser(fetchedUser.user.id);
    };

    fetchPostAndComments();
  }, [id, currentPage]);

  const fetchComments = async (page) => {
    const fetchedComments = await getComments(id, page);
    setComments(fetchedComments.comments);
    setTotalPages(fetchedComments.total_pages);
  };

  const handleAddComment = (newComment) => {
    setComments(prevComments => [newComment, ...prevComments]);
    const newTotalCount = comments.length + 1;
    const newTotalPages = Math.ceil(newTotalCount / 10); // Assuming 10 comments per page
    setTotalPages(newTotalPages);
  };

  const handleDeletePost = async (id) => {
    await deletePost(id);
    Navigate('/');
  };

  return (
    <div className="post-container">
      {post ? (
        <>
          <div className='post-show'>
            {(post.created_by === localStorage.getItem('username')) &&
              <button onClick={() => handleDeletePost(post.id)} className="delete-button" aria-label="Delete">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            }
            <a href={`/profile/${user}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <span className='creator-tag'>By: {post.created_by}</span>
            </a>
            <h3 className='post-title'>{post.title}</h3>

            {post.tags && post.tags.length > 0 && (
              <div className=''>
                {post.tags.map(tag => (
                  <a href={`/filteredposts/${tag.id}`} >
                    <span key={tag.id} className='tag'>{tag.name}</span>
                  </a>
                ))}
              </div>
            )}

            <p className='post-content'>{post.content}</p>

            {post.images && post.images.length > 0 && (
              <div className="post-images">
                {post.images.slice(0, 5).map((image, index) => (
                  <img key={index} src={image.url} alt={`Post image ${index + 1}`} className="post-image" />
                ))}
              </div>
            )}
          </div>
          <CommentBox onAddComment={handleAddComment} />
          <CommentsComponent postId={id} author={post.created_by} comments={comments} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        </>
      ) : <div>Loading...</div>}
    </div>
  );
};

export default PostPage;
