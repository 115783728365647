// CommentsComponent.jsx

import React, { useState, useEffect } from 'react';
import { deleteComment, getUserByUsername } from '../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './commentStyle.css';

const CommentsComponent = ({ postId, author, comments, currentPage, setCurrentPage, totalPages }) => {
  const [userIds, setUserIds] = useState({});

  useEffect(() => {
    const fetchUserIds = async () => {
      const uniqueUsernames = [...new Set(comments.map(comment => comment.created_by))];
      const usernamesToFetch = uniqueUsernames.filter(username => !userIds[username]);

      if (usernamesToFetch.length === 0) return;

      const newUserIds = {};

      await Promise.all(
        usernamesToFetch.map(async (username) => {
          try {
            const fetchedUser = await getUserByUsername(username);
            newUserIds[username] = fetchedUser.user.id;
          } catch (error) {
            console.error(`Failed to fetch user ID for username ${username}`, error);
          }
        })
      );

      setUserIds(prevUserIds => ({ ...prevUserIds, ...newUserIds }));
    };

    fetchUserIds();
    // We include 'comments' and 'userIds' in the dependency array
    // to trigger the effect when comments change or new usernames are added.
  }, [comments, userIds]);

  const handleDeleteComment = async (id) => {
    await deleteComment(id);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={i === currentPage ? 'active' : ''}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className='post-container'>
      <h2>Comments</h2>
      {comments.length === 0 ? (
        <p>No comments yet.</p>
      ) : (
        <>
          <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              &lt;
            </button>
            {renderPageNumbers()}
            <button onClick={handleNext} disabled={currentPage === totalPages}>
              &gt;
            </button>
          </div>

          {comments.map(comment => (
            <div className='post-show' key={comment.id}>
              <span className='creator-tag'>
                By:{' '}
                {userIds[comment.created_by] ? (
                  <a href={`/profile/${userIds[comment.created_by]}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {comment.created_by}
                  </a>
                ) : (
                  comment.created_by
                )}
              </span>
              <p className='post-content'>{comment.comment}</p>
              <span className='creator-tag'>
                {new Date(comment.created_at).toLocaleTimeString()}
              </span>

              {(comment.created_by === localStorage.getItem('username') ||
                author === localStorage.getItem('username')) && (
                <button
                  onClick={() => handleDeleteComment(comment.id)}
                  className="delete-button"
                  aria-label="Delete"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </div>
          ))}

          <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              &lt;
            </button>
            {renderPageNumbers()}
            <button onClick={handleNext} disabled={currentPage === totalPages}>
              &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentsComponent;
